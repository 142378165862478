/* eslint-disable no-unused-expressions */
import axios from 'axios';
import { store } from 'src/redux/store';
import { logout } from 'src/redux/userSlice';
import currentURL from '../env';

const AxiosAll = async (method, url, params, userToken, contentType) => {
  // const baseURL = 'https://api.develpment.node.vanguard.skunktest.work/api/';
  // const baseURL = 'https://api.develpment.laravel.vanguard.skunktest.work/api/';
  // const baseURL = 'https://api.dev.node.vanguard.skunktest.work/api/'; //  production url

  const baseURL = currentURL?.currentEnv;

  return axios({
    method,
    url: `${baseURL}${url}`,
    data: params,
    headers: {
      'Content-Type': contentType ?? 'application/json',
      Authorization: `Bearer ${userToken}`,
    },
  })
    .then((response) => response)
    .catch((error) => {
      if (error?.response?.status === 401) {
        store.dispatch(logout());
      }
      return error?.response;
    });
};
export default AxiosAll;
