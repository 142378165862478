import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
// @mui
import {
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import moment from 'moment';
import { useSelector } from 'react-redux';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Page from 'src/components/Page';
import { useSettingsContext } from 'src/components/settings';
import { formatDateTime, timStampToDate } from 'src/core/constant';
import AxiosAll from 'src/service/axiosAll';
import { fDateTime } from 'src/utils/format-time';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

// ----------------------------------------------------------------------

export default function OrdersDetail() {
  const { themeStretch } = useSettingsContext();

  const navigate = useNavigate();
  const params = useParams();
  const [orders, setOrders] = useState([]);

  // get redux state
  const user = useSelector((state) => state.user);
  const { token } = user;
  const [outcomes, setOutcomes] = useState([]);

  const getVisitDetail = async () => {
    const response = await AxiosAll('get', `orders/${params?.id}`, null, token);
    const { data } = response;
    setOrders(data?.data);
    setOutcomes(data?.outcomes);
    return {};
  };

  useEffect(() => {
    getVisitDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  const methods = useForm({
    resolver: yupResolver(),
  });

  const {
    reset,
    watch,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();
  function handleLinkClick(link) {
    window.open(link, '_blank');
  }

  return (
    <Page title="Orders : Detail">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Orders Details"
          links={[
            { name: 'Dashboard', href: '/dashboard' },
            { name: 'Order list', href: '/dashboard/orders' },
            { name: 'Details' },
          ]}
        />
        <Card>
          <Typography variant="h4" sx={{ p: 3 }}>
            {orders?.user?.fullName}
          </Typography>
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Client Name
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {orders?.customerName}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Type of Client
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {orders?.customerType?.name}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Created At
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {fDateTime(orders?.customerType?.createdAt)}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Order Status
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {orders?.status}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Value of order
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {orders?.valueOfOrder}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Remarks
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {orders?.remarks}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Order Details
                </Typography>
                <TableContainer>
                  <Table aria-label="order table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Order Items</TableCell>
                        <TableCell>Order Quantity</TableCell>
                        <TableCell>Order Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orders?.orderItems?.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item?.products?.name}</TableCell>
                          <TableCell>{item?.quantity}</TableCell>
                          <TableCell>{item?.price}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
