/* eslint-disable import/no-absolute-path */
/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */

export const imagesAll = {
  mainLogo: '/assets/mainLogo.svg',
  largelogo: '/assets/images/Logo.webp',
  smalllogo: '/assets/images/vanguard.webp',
  rsvrSquareLogo: '/assets/images/rsvrtech-squarelogo.png',
  mainWhiteLogo: '/assets/images/vanguard.png',
  authLogo: '/assets/images/auth_logo.png',
  error404: '/assets/images/404.png',
  error403: '/assets/images/403.png',
  error500: '/assets/images/500.png',
  maintenance: '/assets/images/maintenance.png',
  coming_soon: '/assets/images/coming_soon.png',
  product: '/assets/images/product.webp',
  
};
