import { Route, Routes } from 'react-router';
import NotFoundPage from 'src/pages/404';
import LoginPage from 'src/pages/auth/login';
// import DescriptionScreen from 'src/pages/description';

export default function PublicRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="*" element={<LoginPage />} />
      {/* <Route path="/description" element={<DescriptionScreen />} /> */}
    </Routes>
  );
}
