import { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { LoadingScreen } from 'src/components/loading-screen';
import DashboardLayout from 'src/layouts/dashboard/layout';
import NotFoundPage from 'src/pages/404';
import UserReport from 'src/pages/dashboard/graph';
import OrdersDetail from 'src/pages/dashboard/orderDetails';
import SummaryDetail from 'src/pages/dashboard/summaryDetail';
import VisitDetail from 'src/pages/dashboard/visitDetail';
import DescriptionScreen from 'src/pages/description';

const CallLogs = lazy(() => import('src/pages/dashboard/call-logs'));
const Report = lazy(() => import('src/pages/dashboard/report'));
const Orders = lazy(() => import('src/pages/dashboard/orders'));
const Summary = lazy(() => import('src/pages/dashboard/summary'));
const Dashboard = lazy(() => import('src/pages/dashboard/user'));
const Outstanding = lazy(() => import('src/pages/dashboard/outStanding'));
const Products = lazy(() => import('src/pages/dashboard/products'));

export default function AuthRoutes() {
  const { user } = useSelector((state) => state.user);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        }
      >
        {user.roleId === 3 ? (
          <Route path="dashboard/outstanding" element={<Outstanding />} />
        ) : (
          <>
            <Route path="dashboard/user" element={<Dashboard />} />
            <Route path="dashboard/user/:id" element={<UserReport />} />
            <Route path="dashboard/logs" element={<CallLogs />} />
            <Route path="dashboard/summary" element={<Summary />} />
            <Route path="dashboard/summary/:id" element={<SummaryDetail />} />
            <Route path="dashboard/report" element={<Report />} />
            <Route path="dashboard/report/:id" element={<VisitDetail />} />
            <Route path="dashboard/orders" element={<Orders />} />
            <Route path="dashboard/orders/:id" element={<OrdersDetail />} />
            <Route path="dashboard/outstanding" element={<Outstanding />} />
            <Route path="dashboard/products" element={<Products />} />
            <Route path="dashboard/description" element={<DescriptionScreen />} />
            <Route path="*" element={<Dashboard />} />
            <Route path="/" element={<Navigate to="dashboard/user" replace />} />
          </>
        )}
      </Route>
    </Routes>
  );
}
