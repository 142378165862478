import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import { useSelector } from 'react-redux';
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  user: icon('ic_user'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  phone: icon('ic_phone'),
  summary: icon('ic_chat'),
  money: icon('ic_rupee'),
  products: icon('ic_product'),
  orders: icon('ic_order'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { user } = useSelector((state) => state.user);

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: 'Vanguard Sales',
        items: [
          // Conditionally include the "Outstanding" item only when roleId is 2
          ...(user.roleId === 3
            ? [{ title: 'Outstanding', path: paths.dashboard.outstanding, icon: ICONS.money }]
            : [
                { title: 'Users', path: paths.dashboard.dashboard, icon: ICONS.user },
                { title: 'Call Logs', path: paths.dashboard.logs, icon: ICONS.phone },
                { title: 'Call Log Summary', path: paths.dashboard.summary, icon: ICONS.summary },
                { title: 'Visit Reports', path: paths.dashboard.report, icon: ICONS.analytics },
                { title: 'Orders', path: paths.dashboard.orders, icon: ICONS.orders },
                { title: 'Outstanding', path: paths.dashboard.outstanding, icon: ICONS.money },
              ]),
          // ... (other items)
        ],
      },
      ...(user.roleId !== 3
        ? [
            {
              subheader: 'Management',
              items: [{ title: 'Products', path: paths.dashboard.products, icon: ICONS.products }],
            },
          ]
        : []),

      // // MANAGEMENT
      // // ----------------------------------------------------------------------
      // {
      //   subheader: 'management',
      //   items: [
      //     {
      //       title: 'Products',
      //       children: [{ title: 'Users', path: paths.dashboard.products, icon: ICONS.products }],
      //     },
      //   ],
      // },
    ],
    [user.roleId]
  );

  return data;
}
