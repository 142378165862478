import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
// @mui
import { Card, CardContent, Container, Divider, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import moment from 'moment';
import { useSelector } from 'react-redux';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Page from 'src/components/Page';
import { useSettingsContext } from 'src/components/settings';
import { formatDateTime, timStampToDate } from 'src/core/constant';
import AxiosAll from 'src/service/axiosAll';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

// ----------------------------------------------------------------------

export default function VisitDetail() {
  const { themeStretch } = useSettingsContext();

  const navigate = useNavigate();
  const params = useParams();
  const [visit, setVisit] = useState([]);

  // get redux state
  const user = useSelector((state) => state.user);
  const { token } = user;
  const [outcomes, setOutcomes] = useState([]);

  const getVisitDetail = async () => {
    const response = await AxiosAll('get', `visits/${params?.id}`, null, token);
    const { data } = response;
    setVisit(data?.data);
    setOutcomes(data?.outcomes);
    return {};
  };

  useEffect(() => {
    getVisitDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  const methods = useForm({
    resolver: yupResolver(),
  });

  const {
    reset,
    watch,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();
  function handleLinkClick(link) {
    window.open(link, '_blank');
  }

  return (
    <Page title="Visit : Detail">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Visit Details"
          links={[
            { name: 'Dashboard', href: '/dashboard' },
            { name: 'Visit list', href: '/dashboard/report' },
            { name: 'Details' },
          ]}
        />
        <Card>
          <Typography variant="h4" sx={{ p: 3 }}>
            {visit?.user?.fullName}
          </Typography>
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Client Name
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {visit?.customerName}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Type of Client
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {visit?.customerType?.name}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Visit Date
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {timStampToDate(visit?.timestamp)}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Visit Time
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {formatDateTime(visit?.timestamp)}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Meeting Duration
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {visit?.meetingDuration}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Value of order
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {visit?.valueOfOrder}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Visit Outcome
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {visit?.outcome?.name}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  {visit?.outcome?.name === 'Order expected' ? 'Expected Date' : 'Follow-Up Date'}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {visit?.expectedDate ? moment(visit?.expectedDate).format('D MMM') : ''}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Product Range
                </Typography>
                {visit?.products?.map((item, index) => (
                  <div key={index} style={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      style={{ minWidth: '50%', width: '50%', paddingRight: '14px' }}
                    >
                      {item?.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {outcomes.find((e) => e?.id === item?.outcomeId)?.name}
                    </Typography>
                  </div>
                ))}
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Remarks
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {visit?.remarks}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
