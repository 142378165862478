import { Card, Container, Stack, TextField, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { imagesAll } from 'src/assets/Images_index';
import { styled } from '@mui/material/styles';
import Image from 'src/components/Image';
import Page from 'src/components/Page';
import { RHFTextField } from 'src/components/hook-form';
import { useSettingsContext } from 'src/components/settings';
import FormProvider from 'src/components/hook-form/form-provider';

// sections

// ----------------------------------------------------------------------
const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  }));
export default function DescriptionScreen() {
    const { themeStretch } = useSettingsContext();
  return (
    <Page title="Products Description">
    <Container maxWidth={themeStretch ? 'sm' : 'sm'}>
      <Card sx={{borderRadius:'0', p:2, boxShadow:'none'}} >
      <Image src={imagesAll?.product} className="description_img" />
      <Stack direction="row" spacing={2} 
        justifyContent="space-between"
        alignItems="center"
        sx={{mt:2, mb:2,}}
      >
      <Typography>product name </Typography>
      <Typography>Product Price </Typography>
      </Stack>
      <Typography variant='h5' sx={{mb:2, mt:2}} >Description </Typography>
      <TextField id="outlined-basic"  multiline
          rows={4}  variant="outlined"  fullWidth/>
      </Card>
    </Container>
  </Page>
  );
}
